import * as React from "react"
import { useState, useRef, useEffect } from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const CurrentDev = () => {
  // create an array of images
  const PrintSample = [
    {
      id: 1,
      image: (
        <StaticImage
          className={"object-contain aspect-square w-full"}
          loading={"eager"}
          src={"../images/sell-us-your-jewelry-banner.png"}
          alt={"Watches and Jewelry Image"}
        />
      ),
      alt: "Watches and Jewelry Image",
    },
    {
      id: 2,
      image: (
        <StaticImage
          className={"object-contain aspect-square w-full"}
          loading={"eager"}
          src={"../images/ballon-bleu-btn.jpeg"}
          alt={"Watches and Jewelry Image"}
        />
      ),
      alt: "Watches and Jewelry Image",
    },
  ]

  const PrintButton = ({ image, id }) => {
    return (
      <button
        onClick={() => console.log(id)}
        className="border-2 border-black hover:border-red-600"
      >
        {image}
      </button>
    )
  }
  return (
    <section>
      <div className="w-full bg-gray-200 ">
        <div className="grid grid-cols-3 max-w-[1000px] w-10/12 ">
          {PrintSample.map(image => {
            return (
              <div key={image.id} className="p-2">
                <PrintButton image={image.image} id={image.id} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CurrentDev
